/* eslint-disable array-callback-return */
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import {
  fetchGet,
  fetchPut,
  fetchPost,
  getHeaders,
} from "../../../../service/api_client";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { LedgerEntryModel } from "../../../accounts/models/common_model";
import {
  ItemMovementModel,
  ItemStatus,
  stockJournalDefaultValue,
  StockJournalModel,
} from "../../models/inventory_voucher";
import ItemRow from "./components/item_row";
import TaxEntryRow, { getItemTaxAmount } from "./components/tax_entry_row";
import WarehouseSearchField from "../../warehouse/warehouse_search_field";
import axios from "axios";
import { useGoogleLogout } from "react-google-login";
import ItemRowStatus, { generate_id } from "./components/item_row_status";

interface StockJournalProps {}

const DestinationStockJournal: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/destination_transfer`;
  const [, setLoading] = useState(false);
  // const [addNewId2, setAddNewId2] = useState(Math.random() * 1000);
  // const [addNewTaxId2, setAddNewTaxId2] = useState(Math.random() * 1000);
  const { showToast } = useToast();
  const [editable] = useState(false);
  const [data, setData] = useState<StockJournalModel>({
    ...stockJournalDefaultValue,
  });
  const google_props = {
    clientId:
      "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com",
  };

  const { signOut } = useGoogleLogout(google_props);

  useEffect(() => {
    getUserData();
    if (id) getVoucher(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function getUserData() {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/data`;
      const res = (
        await axios.get(url, {
          withCredentials: true,
          headers: { ...getHeaders() },
        })
      ).data;

      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          user: res.data || prevData.user, // Ensure existing data is preserved
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      const { response } = error;
      if (response && response.status === 401) {
        signOut();
        window.localStorage.removeItem("token");
      }
    }
    setLoading(false);
  }

  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      if (
        res.data.destination.length === 0 ||
        res.data.destination.length !== res.data.source.length
      ) {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
          destination: res.data.source,
          destination_taxes: res.data.source_taxes,
          // destination_tax:res.data.source_tax,
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
          destination: res.data.destination,
          // destination_taxes: res.data.source_taxes,
          // destination_tax:res.data.source_tax,
        }));
      }
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const submit = async () => {
    if (!data.destination?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add destination product",
      });
    setLoading(true);

    const res = id
      ? await fetchPut(url + "/" + id, data)
      : await fetchPost(url, data);
    if (res.success) {
      setData((prevData) => ({
        ...prevData,
        ...res.data,
      }));
      showToast({ type: ToastType.success, text: res.message });
      // props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const handleAddRow = (item: ItemMovementModel) => {
    const lastItem = data.destination?.find((d) => d.id === item.id);
    if (lastItem && lastItem.status === ItemStatus.partialReceipt) {
      const remainingUnits = lastItem.unit_no - lastItem.received_unit_no;
      // setData((prevData) => ({
      //   ...prevData,
      //   destination: [
      //     ...(prevData.destination || []),
      //     {
      //       ...lastItem,
      //       id: generate_id(), // Unique id for new row
      //       received_unit_no: 0, // Reset for new row
      //       status: ItemStatus.in_transit,
      //       unit_no: remainingUnits, // Set remaining units
      //     },
      //   ],
      // }));
      setData((prevData) => ({
        ...prevData,
        disposalItems: [
          ...(prevData.disposalItems || []),
          {
            ...lastItem,
            id: generate_id(), // Unique id for new row
            received_unit_no: 0, // Reset for new row
            lost_unit_no: 0,
            // status: ItemStatus.lost,
            // unit_no: lastItem.received_unit_no,
            unit_no: remainingUnits, // Set remaining units
          },
        ],
      }));
    }
  };
  // function to add a new entry when item_status is lost or damaged or scrapped.
  const handleAddNewEntry = (newEntry: ItemMovementModel) => {
    setData((prevData) => {
      const updatedDestination = [...(prevData.disposalItems || []), newEntry];
      // Recalculate totals and taxes if needed
      // const updatedDestinationTaxes = prevData.destination_taxes.map((t) => {
      //   t.amount = getItemTaxAmount({
      //     items: updatedDestination,
      //     ledgerTaxDetail: t.ledger!.tax_detail!,
      //   });
      //   return { ...t };
      // });

      // const updatedDestinationTax = updatedDestinationTaxes.reduce(
      //   (pv, val) => pv + val.amount,
      //   0
      // );
      const updatedDestinationSubTotal = updatedDestination.reduce(
        (pv, val) => pv + val.unit_no * val.price,
        0
      );
      const updatedDestinationTotal = updatedDestinationSubTotal;

      return {
        ...prevData,
        disposalItems: updatedDestination,
        destination_sub_total: updatedDestinationSubTotal,
        destination_total: updatedDestinationTotal,
      };
    });
  };

  return (
    <>
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Material Received
          </div>
          {/* <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div> */}
        </div>
        <div className="bg-white rounded mb-5 flex gap-1 h-auto overflow-auto">
          <div className="grid grid-cols-2 gap-20 p-5 text-sm w-3/4 font-semibold rounded-t top-0">
            <div className="">
              <div className="">
                <div className="font-bold">Receiver</div>
                <input
                  value={`${data.user?.first_name || ""} ${
                    data.user?.last_name || ""
                  }`}
                  placeholder="receiver"
                  type="text"
                  disabled={!editable}
                  className="text-left p-1 focus:outline-none w-full"
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      user: {
                        ...prevData.user!,
                        first_name: e.target.value,
                        last_name: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="">
                <div className="font-bold">Receiver Department</div>
                <input
                  value={data.user?.emp_profile?.department}
                  placeholder="Department"
                  disabled={!editable}
                  type="text"
                  className="text-left p-1 focus:outline-none w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      department: d.user?.emp_profile?.department,
                    }))
                  }
                />
              </div>
              <div className="font-bold">Delivery Challan Number</div>
              <input
                value={data.delivery_challan_number}
                placeholder="Delivery Challan"
                type="text"
                disabled={!editable}
                className="text-left p-1 focus:outline-none border rounded w-full"
                onChange={(e) =>
                  setData((d) => ({
                    ...d,
                    delivery_challan_number: e.target.value,
                  }))
                }
                readOnly
              />
              <div className="font-bold">Tracking/Voucher_Id</div>
              <input
                value={data.destination?.[0]?.voucher_id}
                placeholder="Tracking/Voucher_Id"
                type="text"
                disabled={!editable}
                name="tracking"
                id="tracking"
                className="text-left p-1 focus:outline-none border rounded w-full"
                onChange={(e) =>
                  setData((d) => ({
                    ...d,
                    voucher_no: e.target.value,
                  }))
                }
                readOnly
              />
              <label htmlFor="" className="text-sm font-bold">
                Received Date
              </label>{" "}
              <input
                type="date"
                className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value)!;
                  setData((o) => ({
                    ...o,
                    date: date,
                  }));
                }}
              />
            </div>
            <div className="mr-0">
              <div className="font-bold text-md mb-3">To</div>
              <div className="font-bold">Warehouse/office</div>
              <WarehouseSearchField
                type={""}
                disabled={!editable}
                value={data.receiver}
                placeholder="Inventory Basket"
                onSelect={(d) => {
                  setData((old) => {
                    return {
                      ...old,
                      receiver: d,
                    };
                  });
                }}
              />
              <div className="font-bold">User/Receiver</div>
              <input
                value={data.receiver?.spoc?.contact_name}
                placeholder=""
                type="text"
                className="text-left p-1 focus:outline-none border rounded w-full"
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    spoc: {
                      ...prevData.receiver?.spoc,
                      contact_name: e.target.value,
                    },
                  }));
                }}
              />
              {/* <div className="">
                <div className="font-bold">Department</div>
                <input
                  value={data.receiver?.spoc?.department}
                  placeholder="Department"
                  type="text"
                  className="text-left p-1 focus:outline-none border rounded w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      department: data.receiver?.spoc?.department,
                    }))
                  }
                />
              </div> */}
              <div className="font-bold">Responsible Person(SPOC)</div>

              <input
                value={data.receiver_spoc?.contact_name || ""}
                placeholder="receiver"
                type="text"
                disabled={!editable}
                className="text-left p-1 focus:outline-none w-full"
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    receiver_spoc: {
                      ...prevData.receiver_spoc!,
                      name: e.target.value,
                    },
                  }));
                }}
                readOnly
              />

              <div className="">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Spoc Department
                  </label>
                  <input
                    placeholder="Phone"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="phone"
                    name="phone"
                    value={data.receiver_spoc?.department}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        receiver_spoc: {
                          ...prevData.receiver_spoc!,
                          department: e.target.value,
                        },
                      }))
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {console.log("Destiination taxes:", data.destination_taxes)}
        <div className="flex flex-col gap-5 items-start justify-start">
          <div className="">
            <h3 className="text-sm font-bold">Destination(production)</h3>
            <div className="bg-white mt-5  rounded flex flex-col gap-1 h-auto overflow-auto">
              <div className="grid grid-cols-11 gap-5 p-1  bg-myPrimaryColor text-white text-sm font-semibold rounded-t  sticky top-0">
                <div className="text-left">Entry Date</div>
                <div className="text-center">Item</div>
                <div className="text-center">Warehouse</div>
                <div className="text-center">Qty</div>
                <div className="">Received Qty</div>
                <div className="">Project From</div>
                <div className="">Weighted Price</div>
                <div className="">Amount</div>
                <div className="">Project to</div>
                <div className="">Status</div>
              </div>
              <div className="mb-20">
                {data.destination?.map((comp, i) => {
                  // const showAddButton =
                  //   comp.status === ItemStatus.partialReceipt;

                  return (
                    <ItemRow
                      item={comp}
                      edit={false}
                      disabled={false}
                      key={i}
                      onDelete={(d) => {
                        setData((o) => {
                          const destination = [
                            ...(o.destination || []).filter(
                              (it) => it.id !== d.id
                            ),
                          ];
                          const destination_taxes = o.destination_taxes.map(
                            (t) => {
                              t.amount = getItemTaxAmount({
                                items: destination,
                                ledgerTaxDetail: t.ledger!.tax_detail!,
                              });

                              return { ...t };
                            }
                          );
                          let destination_tax = destination_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const destination_sub_total = destination.reduce(
                            (pv, val) => pv + val.received_unit_no * val.price,
                            0
                          );
                          const destination_total =
                            destination_sub_total + destination_tax;

                          return {
                            ...o,
                            destination,
                            destination_sub_total,
                            destination_taxes,
                            destination_tax,
                            destination_total,
                          };
                        });
                      }}
                      onSubmit={(d) => {
                        setData((o) => {
                          const destination = [
                            ...(o.destination || []).map((it) =>
                              it.id === d.id ? d : it
                            ),
                          ];
                          const destination_taxes = o.destination_taxes.map(
                            (t) => {
                              t.amount = getItemTaxAmount({
                                items: destination,
                                ledgerTaxDetail: t.ledger!.tax_detail!,
                              });

                              return { ...t };
                            }
                          );
                          let destination_tax = destination_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const destination_sub_total = destination.reduce(
                            (pv, val) => pv + val.received_unit_no * val.price,
                            0
                          );
                          const destination_total =
                            destination_sub_total + destination_tax;

                          return {
                            ...o,
                            destination,
                            destination_sub_total,
                            destination_tax,
                            destination_total,
                          };
                        });
                      }}
                      index={i}
                      // remainingUnits={comp.unit_no - comp.received_unit_no}
                      // showAddButton={showAddButton}
                      // onAddRow={handleAddRow} // Pass handleAddRow as callback
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Taxes</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-left">Amount</div>
              </div>

              {data.destination_taxes.map((tax, i) => {
                return (
                  <TaxEntryRow
                    key={tax.amount}
                    entry={tax}
                    items={[...(data.destination || [])]}
                    onDelete={(d) => {
                      setData((o) => {
                        const destination_taxes = [
                          ...(o.destination_taxes || []),
                        ].filter((i) => i.id !== d.id);
                        let destination_tax = destination_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const destination_total =
                          o.destination_sub_total + (destination_tax ?? 0);
                        return {
                          ...o,
                          destination_taxes,
                          destination_tax,
                          destination_total,
                        };
                      });
                    }}
                    onSubmit={function (d: LedgerEntryModel): void {
                      setData((o) => {
                        const destination_taxes = [
                          ...(o.destination_taxes || [[]]).map((t) => {
                            if (t.id === d.id) {
                              return { ...d };
                            }
                            return { ...t };
                          }),
                        ].map((t, i) => {
                          t.amount = getItemTaxAmount({
                            items: data.destination || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                          });

                          return { ...t };
                        });
                        let destination_tax = destination_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const destination_total =
                          o.destination_sub_total + (destination_tax ?? 0);
                        return {
                          ...o,
                          destination_taxes,
                          destination_tax,
                          destination_total,
                        };
                      });
                    }}
                    edit={false}
                    disabled={false}
                    existingEntries={data.destination_taxes}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="font-bold text-sm p-5">Updation for Non-Receipt</div> */}
      {/* table for partially recieved or lost  acc to received quantity is less then qty condition. */}
      {/* <div className="bg-white rounded flex flex-col mx-5 gap-1 h-auto overflow-auto">
        <div className="grid grid-cols-10 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
          <div className="text-center">Date</div>
          <div className="text-center">Item</div>
          <div className="text-center">Warehouse</div>
          <div className="text-center">Qty Pending</div>
          <div className="">Project</div>
          <div className="">Qty for Status</div>
          <div className="">Price</div>
          <div className="">Amount</div>
          <div className="">Status Update</div>
        </div>
        {data.disposalItems?.map((comp, i) => {
          // Determine if the add button should be shown based on the item's status
          const showAddButton =
            (comp.status === ItemStatus.lost ||
              comp.status === ItemStatus.damaged ||
              comp.status === ItemStatus.scrapped) &&
            comp.lost_unit_no < comp.unit_no;

          return (
            <ItemRowStatus
              key={comp.id || i} // Ensure each row has a unique key
              item={comp}
              edit={false}
              disabled={false}
              onDelete={(d) => {
                setData((o) => {
                  const disposalItems = [
                    ...(o.disposalItems || []).filter((it) => it.id !== d.id),
                  ];
                  // const destination_taxes = o.destination_taxes.map((t) => {
                  //   t.amount = getItemTaxAmount({
                  //     items: disposalItems,
                  //     ledgerTaxDetail: t.ledger!.tax_detail!,
                  //   });

                  //   return { ...t };
                  // });
                  // let destination_tax = destination_taxes.reduce(
                  //   (pv, val) => pv + val.amount,
                  //   0
                  // );
                  const destination_sub_total = disposalItems.reduce(
                    (pv, val) => pv + val.lost_unit_no * val.price,
                    0
                  );
                  const destination_total = Math.round(destination_sub_total);

                  return {
                    ...o,
                    disposalItems,
                    // destination_sub_total,
                    // destination_taxes,
                    // destination_tax,
                    // destination_total,
                  };
                });
              }}
              onAddNewEntry={handleAddNewEntry}
              // remainingUnits={
              //   comp.unit_no - comp.received_unit_no - comp.lost_unit_no
              // }
              showAddButton={showAddButton} // Pass the boolean indicating if the add button should be shown
              onSubmit={(d) => {
                setData((o) => {
                  const disposalItems = [
                    ...(o.disposalItems || []).map((it) =>
                      it.id === d.id ? d : it
                    ),
                  ];
                  // const destination_taxes = o.destination_taxes.map((t) => {
                  //   t.amount = getItemTaxAmount({
                  //     items: disposalItems,
                  //     ledgerTaxDetail: t.ledger!.tax_detail!,
                  //   });

                  //   return { ...t };
                  // });
                  // let destination_tax = destination_taxes.reduce(
                  //   (pv, val) => pv + val.amount,
                  //   0
                  // );
                  const destination_sub_total = disposalItems.reduce(
                    (pv, val) => pv + val.unit_no * val.price,
                    0
                  );
                  const destination_total = destination_sub_total;

                  return {
                    ...o,
                    disposalItems,
                    // destination_sub_total,
                    // destination_total,
                  };
                });
              }}
              index={i}
            />
          );
        })}
      </div> */}

      <div className="m-7">
        <div className="text-sm flex justify-end">
          <div className="flex flex-col gap-2">
            <div className=" flex gap-5 justify-between">
              <div className="">Destination subtotal:</div>
              <div className="">{data.destination_sub_total}</div>
            </div>
            <div className=" flex gap-5 justify-between">
              <div className="">Destination tax:</div>
              <div className="">{data.destination_tax}</div>
            </div>
            <div className=" flex gap-5 justify-between">
              <div className="">Destination total:</div>
              <div className="">{data.destination_total}</div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-end px-6 py-2">
        <button
          onClick={submit}
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
        >
          Inward
        </button>
      </div>
    </>
  );
};
export default DestinationStockJournal;
