import { mdiCheck, mdiClose, mdiPencil, mdiDelete, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../../../../service/api_client";
import ItemSearchField from "../../../../items/item_search_field";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
} from "../../../../models/inventory_voucher";
import { ItemModel } from "../../../../models/item_model";
import WarehouseSearchField from "../../../../warehouse/warehouse_search_field";
import { WAREHOUSE_TYPE } from "../../../../models/warehouse_model";
import ProjectSearchField from "../../../../../purchase_order/projects/project_master/project_search_field";
import { useLocation } from "react-router-dom";

interface ItemRowProps {
  component?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModel) => void;
  existingItems: ItemMovementModel[];
}

const ItemRow: FunctionComponent<ItemRowProps> = (props) => {
  const [data, setData] = useState<ItemMovementModel>({
    ...itemMovementDefaultValue,
    // inventory: [], // Store warehouse data
  });

  const [selectedItem, setSelectedItem] = useState<ItemModel | null>(null);
  const { showToast } = useToast();
  const { edit, onSubmit, existingItems } = props;
  const [editable, setEditable] = useState(false);
  const [check, setMdiCheck] = useState(true);
  const [, setLoading] = useState(false);
  const [, setHasStock] = useState(false);

  const location = useLocation();
  const isCreatePath = location.pathname.endsWith("/create");

  useEffect(() => {
    setEditable(edit);
    if (props.component) {
      setData(props.component);
      setData((prevData) => ({
        ...prevData,
        item: props.component?.item,
        item_id: props.component?.id,
      }));
    } else {
      setData({
        ...itemMovementDefaultValue,
        // inventory: [], // Initialize warehouses if necessary
      });
    }
  }, [edit, props.component]);

  useEffect(() => {
    if (props.component) {
      setData((prevData) => ({
        ...prevData,
        ...props.component,
      }));
    }
  }, [props.component]);

  const isDuplicate = (item: ItemModel, warehouse: any) => {
    return existingItems.some(
      (existing) =>
        existing.item_id === item.id &&
        existing.warehouse.id === warehouse.id &&
        check
    );
  };

  // const updatePriceBasedOnUnits = (requestedUnits: number) => {
  //   if (!data.warehouse || !selectedItem) return;

  //   const warehouseData = data.inventory.find(
  //     (w: any) => w.warehouse.id === data.warehouse?.id
  //   );

  //   if (!warehouseData) {
  //     showToast({
  //       type: ToastType.warning,
  //       text: "No stock available in the selected warehouse.",
  //     });
  //     setHasStock(false);
  //     return;
  //   }

  //   const { inwards, outwards } = warehouseData;

  //   // Calculate available units by deducting outwards from inwards
  //   let cumulativeInwards = inwards.map((item: any) => ({
  //     ...item,
  //     remaining: item.unit_no,
  //   }));

  //   // Deduct outwards from the inwards items to get the remaining units
  //   for (const out of outwards) {
  //     let unitsToDeduct = out.unit_no;
  //     for (const inItem of cumulativeInwards) {
  //       if (unitsToDeduct <= 0) break;
  //       if (inItem.remaining > 0) {
  //         const unitsToRemove = Math.min(unitsToDeduct, inItem.remaining);
  //         inItem.remaining -= unitsToRemove;
  //         unitsToDeduct -= unitsToRemove;
  //       }
  //     }
  //   }

  //   // Process the requested units
  //   let remainingUnits = requestedUnits;
  //   let amount = 0;
  //   let unitsProcessed = 0;

  //   for (const item of cumulativeInwards) {
  //     if (remainingUnits <= 0) break;
  //     if (item.remaining > 0) {
  //       const unitsToAllocate = Math.min(remainingUnits, item.remaining);
  //       amount += unitsToAllocate * item.price;
  //       remainingUnits -= unitsToAllocate;
  //       unitsProcessed += unitsToAllocate;
  //     }
  //   }

  //   if (remainingUnits > 0) {
  //     showToast({
  //       type: ToastType.warning,
  //       text: "Requested units exceed available stock.",
  //     });
  //   }

  //   setData((prevData) => ({
  //     ...prevData,
  //     amount,
  //     price: Math.round(unitsProcessed > 0 ? amount / unitsProcessed : 0),
  //   }));

  //   setHasStock(true);
  // };
  const updatePriceBasedOnUnits = (requestedUnits: number) => {
    if (!data.warehouse || !selectedItem) return;

    const warehouseData = data.inventory.find(
      (w: any) => w.warehouse.id === data.warehouse?.id
    );

    if (!warehouseData) {
      showToast({
        type: ToastType.warning,
        text: "No stock available in the selected warehouse.",
      });
      setHasStock(false);
      return;
    }

    const { inwards, outwards } = warehouseData;

    // Calculate available units by deducting outwards from inwards
    let cumulativeInwards = inwards.map((item: any) => ({
      ...item,
      remaining: item.unit_no,
    }));

    // Deduct outwards from the inwards items to get the remaining units
    for (const out of outwards) {
      let unitsToDeduct = out.unit_no;
      for (const inItem of cumulativeInwards) {
        if (unitsToDeduct <= 0) break;
        if (inItem.remaining > 0) {
          const unitsToRemove = Math.min(unitsToDeduct, inItem.remaining);
          inItem.remaining -= unitsToRemove;
          unitsToDeduct -= unitsToRemove;
        }
      }
    }

    // Process the requested units and update projectFrom
    let remainingUnits = requestedUnits;
    let amount = 0;
    let unitsProcessed = 0;

    // Collect projects associated with the requested units
    const projectsSet = new Set<string>();

    for (const item of cumulativeInwards) {
      if (remainingUnits <= 0) break;
      if (item.remaining > 0) {
        const unitsToAllocate = Math.min(remainingUnits, item.remaining);
        amount += unitsToAllocate * item.price;
        remainingUnits -= unitsToAllocate;
        unitsProcessed += unitsToAllocate;
        projectsSet.add(item.projectFrom);
      }
    }

    if (remainingUnits > 0) {
      showToast({
        type: ToastType.warning,
        text: "Requested units exceed available stock.",
      });
    }

    // Convert the Set to an array
    const projectsArray = Array.from(projectsSet);

    setData((prevData) => ({
      ...prevData,
      amount,
      price: Math.round(unitsProcessed > 0 ? amount / unitsProcessed : 0),
      projectFrom: projectsArray, // Update projectFrom with the collected projects
    }));

    setHasStock(true);
  };

  // console.log("projects are", data.projectFrom);

  const onDone = () => {
    if (!data.item || !data.warehouse || data.price <= 0 || !data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please select Item, Warehouse, Price, and Quantity",
      });
      return;
    }
    // if (isDuplicate(data.item, data.warehouse)) {
    //   showToast({
    //     type: ToastType.warning,
    //     text: "This item already exists for the selected warehouse.",
    //   });
    // }
    console.log("data getting ", data);
    onSubmit(data);
  };

  const handleWarehouseSelect = async (warehouse: any) => {
    if (!selectedItem) {
      showToast({
        type: ToastType.warning,
        text: "Please select an item first.",
      });
      return;
    }
    if (isDuplicate(selectedItem, warehouse)) {
      showToast({
        type: ToastType.warning,
        text: "This item already exists for the selected warehouse.",
      });
      return;
    }

    setData((prevData) => ({
      ...prevData,
      warehouse,
      unit_no: 0, // Reset units
      amount: 0, // Reset price
    }));

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${selectedItem.id}/value?warehouse_id=${warehouse.id}`;
    setLoading(true);

    try {
      const res = await fetchGet(url);
      console.log(res.data);
      if (res.success) {
        const { item_data } = res.data;

        // Extract inwards and outwards
        const inwards = item_data[0]?.inwards?.items || [];
        const outwards = item_data[0]?.outwards?.items || [];

        if (inwards.length === 0 && outwards.length === 0) {
          showToast({
            type: ToastType.warning,
            text: "No available stock for this item.",
          });
          setHasStock(false);
          return;
        }

        setData((prevData) => ({
          ...prevData,
          warehouse,
          inventory: [
            {
              warehouse: warehouse,
              inwards,
              outwards,
            },
          ],
          unit_no: 0, // Reset units
          amount: 0, // Reset price
        }));

        setHasStock(true);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching warehouse stock value",
      });
    } finally {
      setLoading(false);
    }
  };

  const getProjectDisplayText = () => {
    const projects = data.projectFrom || [];
    if (projects.length === 0) return "";
    if (projects.length === 1) return projects[0];
    return `${projects[0]} + ${projects.length - 1} more`;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onDone();
      }}
      className="bg-white p-1"
    >
      <div className="grid grid-cols-8 gap-1 items-center">
        <div className="col-span-2">
          <ItemSearchField
            disabled={!editable || props.disabled}
            value={data.item}
            onSelect={(item: ItemModel) => {
              setSelectedItem(item);
              setData((prevData) => ({
                ...prevData,
                item: item,
                item_id: item.id,
              }));
              // Fetch stock value when item is selected
              // if (data.warehouse) {
              //   handleWarehouseSelect(data.warehouse);
              // }
            }}
          />
        </div>
        <div className="">
          <WarehouseSearchField
            type={WAREHOUSE_TYPE.warehouse}
            disabled={!editable || props.disabled}
            value={data.warehouse}
            onSelect={handleWarehouseSelect}
          />
        </div>
        <input
          value={data.unit_no || ""}
          onChange={(e) => {
            let unit_no = 0;
            if (e.target.value && !isNaN(e.target.value as any)) {
              unit_no = Number(e.target.value);
            }
            setData((prevData) => ({
              ...prevData,
              unit_no,
            }));
            if (data.warehouse) {
              updatePriceBasedOnUnits(unit_no);
            }
          }}
          disabled={!editable || props.disabled}
          placeholder=""
          type="text"
          className="text-center p-1 focus:outline-none border rounded w-full"
        />
        {/* <ProjectSearchField
          disabled={false}
          placeholder="Select Project"
          value={data.projectFrom}
          onSelect={(project) => {
            setData((prevData) => ({
              ...prevData,
              projectFrom: {
                ...prevData.projectFrom!,
                name: project.name,
                description: project.description,
                amount: project.amount,
                spent: project.spent,
                batch_name: project.batch_name,
                batch_size: project.batch_size,
                category: project.category,
              },
            }));
          }}
        /> */}
        <input
          value={getProjectDisplayText()}
          disabled={!editable || props.disabled}
          placeholder="project"
          type="text"
          className="text-center p-1 focus:outline-none border rounded w-full"
        />

        <input
          value={data.price || 0}
          onChange={(e) => {
            let price = 0;
            if (e.target.value && !isNaN(e.target.value as any)) {
              price = Number(e.target.value);
            }
            setData((prevData) => ({ ...prevData, price }));
          }}
          placeholder=""
          type="text"
          className="text-center p-1 focus:outline-none border rounded"
        />
        <input
          value={data.amount || ""}
          onChange={(e) => {
            let amount = 0;
            if (e.target.value && !isNaN(e.target.value as any)) {
              amount = Number(e.target.value);
            }
            setData((prevData) => ({ ...prevData, amount }));
          }}
          disabled={!editable || props.disabled}
          placeholder=""
          type="text"
          className="text-center p-1 focus:outline-none border rounded"
        />

        <div className="flex gap-2 items-center justify-end">
          {props.component ? (
            !props.disabled && (
              <>
                <div className="flex justify-center gap-2">
                  {/* {console.log("voucher_id", data.voucher_id)} */}
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure!")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4" />
                      </div>
                      <div
                        onClick={() => {
                          setData(props.component || itemMovementDefaultValue);
                          setEditable(false);
                        }}
                        className="bg-gray-100 p-1 rounded text-gray-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4" />
                      </div>
                    </>
                  ) : (
                    (!data.voucher_id || isCreatePath) && (
                      <div
                        onClick={() => {
                          setEditable(true);
                          setMdiCheck(false);
                        }}
                        className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4" />
                      </div>
                    )
                  )}
                  <div
                    onClick={() => {
                      if (props.onDelete) {
                        props.onDelete(props.component!);
                      }
                    }}
                    className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4" />
                  </div>
                </div>
              </>
            )
          ) : (
            <div
              onClick={() => {
                setEditable(false);
                onDone();
              }}
              className="bg-blue-100 p-1 rounded text-blue-800 hover:scale-110 transform duration-200"
            >
              <Icon path={mdiPlus} className="h-4 w-4" />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ItemRow;
