import { UserModel } from "../../../context_providers/auth/user_model";
import { BaseModel } from "../../../service/models/base";
import {
  LedgerEntryModel,
  voucherType,
} from "../../accounts/models/common_model";
import { ProcurementProjectModel } from "../../purchase_order/projects/model/project_model";
import { itemDefaultValue, ItemModel } from "./item_model";
import { SpocModel, warehouseDefaultValue, WarehouseModel } from "./warehouse_model";

export const StockMovementType = {
  inward: "Inward",
  outward: "Outward",
};

export const ItemStatus = {
  in_transit: "In Transit",
  completed: "Completed",
  scrapped: "Scrapped",
  lost: "Lost",
  damaged: "Damaged",
  partialReceipt: "Partial Receipt",
  failed: "Failed",
};

export const voucherStatus = {
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  return: "Returned"
}

// export interface StockVoucherItem {
//   id: string;
//   item_id: string;
//   item?: ItemModel;
//   bill_unit_no: number;
//   unit_no: number;
//   received_unit_no:number;
//   price: number;
//   projectFrom:ProcurementProjectModel;
//   projectTo:ProcurementProjectModel;
//   item_split?: ItemMovementModel[];
// }

export interface ItemMovementModel extends BaseModel {
  [x: string]: any;
  date: Date;
  item?: ItemModel;
  item_id: string;
  bill_unit_no: number;
  unit_no: number;
  received_unit_no: number;
  lost_unit_no: number;
  price: number;
  amount: number;
  movement_type: string;
  voucher_type: string;
  voucher_id: string;
  voucher_no: string;
  status: string;
  warehouse_id: string;
  warehouse: WarehouseModel;
  projectFrom?: string[];
  projectTo?: ProcurementProjectModel;
  item_split?: ItemMovementModel[];
}

export interface StockManufacturingJournalModel extends BaseModel {
  voucher_type: string;
  voucher_no: string;
  date: Date;
  product_item_entry: ItemMovementModel;
  component_entries?: ItemMovementModel[];
  // co_products?: ItemMovementModel[];
  additional_cost_entries?: LedgerEntryModel[];
  component_cost: number;
  additional_cost: number;
  total: number;
}
export interface StockJournalModel extends BaseModel {
  date?: Date;
  voucher_type: string;
  voucher_no: string;
  source?: ItemMovementModel[];
  destination?: ItemMovementModel[];
  disposalItems?: ItemMovementModel[];
  source_taxes: LedgerEntryModel[];
  source_tax_ids: string[];
  source_tax: number;
  destination_taxes: LedgerEntryModel[];
  destination_tax_ids: string[];
  destination_tax: number;
  source_sub_total: number;
  destination_sub_total: number;
  source_total: number;
  destination_total: number;
  delivery_challan_number: string;
  due_date_for_shipment?: Date;
  // warehouse?: WarehouseModel;
  receiver_spoc?: SpocModel;
  user?: UserModel;
  receiver?: WarehouseModel;
  status: string;//pending or completed acc to items status in voucher status
}
export interface StockItemModel {
  id: string;
  _id: string;
  date: Date;
  item_id: string;
  warehouse_id?: string;
  total_inward_qty: number;
  total_inward_value: number;
  closing_qty: number;
  item: ItemModel;
  warehouse?: WarehouseModel;
}

export const itemMovementDefaultValue: ItemMovementModel = {
  item: itemDefaultValue,
  date: new Date(),
  bill_unit_no: 0,
  unit_no: 0,
  received_unit_no: 0,
  lost_unit_no: 0,
  price: 0,
  amount: 0,
  movement_type: "",
  voucher_type: "",
  voucher_no: "",
  warehouse_id: "",
  status: "",
  warehouse: warehouseDefaultValue,
  item_id: "",
  voucher_id: "",
};

export const stockManufacturingDefaultValue: StockManufacturingJournalModel = {
  date: new Date(),
  voucher_type: voucherType.manufacturing,
  voucher_no: "",
  product_item_entry: itemMovementDefaultValue,
  component_cost: 0,
  additional_cost: 0,
  total: 0,
};

export const stockJournalDefaultValue: StockJournalModel = {
  date: new Date(),
  voucher_type: "",
  voucher_no: "",
  source_total: 0,
  destination_total: 0,
  source: [],
  destination: [],
  disposalItems: [],
  source_taxes: [],
  destination_taxes: [],
  source_tax_ids: [],
  source_tax: 0,
  destination_tax_ids: [],
  destination_tax: 0,
  source_sub_total: 0,
  destination_sub_total: 0,
  delivery_challan_number: "",
  due_date_for_shipment: new Date(),
  status: voucherStatus.pending,
};
